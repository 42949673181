import React, { FC, useMemo, useState, useCallback, useRef, useEffect } from 'react';
import { Link } from 'gatsby';
import classNames from 'classnames';
import YouTube, { Options } from 'react-youtube';
import { YouTubePlayer } from 'youtube-player/dist/types';

import useOnViewport from 'hooks/useOnViewport';
import VideoButton from 'components/common/Video/VideoButton';
import GatsbyImage from 'components/common/GatsbyImage';
import NFButton from 'components/common/NFButton';
import { LABEL_DARK_FONT_TEXT, LABEL_LIGHT_FONT_TEXT } from 'utils/constants';
import { removeHTMLTags } from 'utils/helpers';
import getId from './helpers';
import { IVideoComponentProps } from './model';

import './Video.scss';

const Video: FC<IVideoComponentProps> = ({
  btnPlay,
  btnPlayAriaLabel,
  previewImg,
  video,
  videoTitle,
  videoTitleLink,
  videoDescription,
  btn,
  isVideoRounded,
  videoBottomOverlappingTitle,
  videoOverlappingLabel,
}) => {
  const videoWrapperRef = useRef<HTMLDivElement>(null);
  const [started, setStarted] = useState<boolean>(false);
  const htmlVideo = useRef<HTMLVideoElement>(null);
  const [youtubeVideo, setYoutubeVideo] = useState<YouTubePlayer>();
  const [isVideoPlaying, setVideoPlaying] = useState<boolean>(false);
  const videoId = useMemo(() => (video ? getId(video) : ''), [video]);

  const isVideoOnViewport = useOnViewport(videoWrapperRef);

  useEffect(() => {
    if (!youtubeVideo) return;
    youtubeVideo.getIframe().setAttribute('tabindex', -1);
  }, [youtubeVideo]);

  const toggleStart = useCallback(() => {
    setStarted(!started);
    youtubeVideo?.playVideo();
    htmlVideo?.current?.play();
  }, [youtubeVideo, htmlVideo]);

  const YToptions: Options = {
    playerVars: {
      rel: 0,
      showinfo: 0,
      iv_load_policy: 3,
      modestbranding: 1,
    },
    host: 'https://www.youtube-nocookie.com',
  };

  const onYTVideoReady = (event) => {
    setYoutubeVideo(event.target);
  };

  const onYTVideoEnded = () => {
    setStarted(false);
  };

  const onYTPlayerStateChange = (event) => {
    setVideoPlaying(event.data === 1);
  };

  const toggleHTMLVideoPlay = () => {
    htmlVideo?.current && setVideoPlaying(!htmlVideo?.current?.paused);
  };

  const pauseVideos = () => {
    htmlVideo?.current?.pause();
    youtubeVideo?.pauseVideo();
  };

  useEffect(() => {
    if (isVideoPlaying && !isVideoOnViewport) {
      pauseVideos();
    }
  }, [htmlVideo, youtubeVideo, isVideoPlaying, isVideoOnViewport]);

  const videoClasses = (specificClass) =>
    classNames(specificClass, {
      [`${specificClass}--visible`]: started,
    });

  const videoFrameClasses = classNames('video-frame', {
    'video-frame--rounded': isVideoRounded,
  });

  return (
    <div className="video-holder" data-test="VideoElement">
      {videoTitle || videoDescription || videoTitleLink ? (
        <div className="viteo-text-wrapper">
          {videoTitleLink ? (
            <Link
              to={videoTitleLink[0].url}
              className="video-title"
              aria-label={videoTitleLink[0].name}
            >
              <span>{videoTitleLink[0].name}</span>
            </Link>
          ) : null}
          {videoTitle && !videoTitleLink ? <h2 className="video-title">{videoTitle}</h2> : null}
          {videoDescription ? (
            <p className="video-text">{removeHTMLTags(videoDescription)}</p>
          ) : null}
        </div>
      ) : null}
      <div className={videoFrameClasses} ref={videoWrapperRef}>
        {videoOverlappingLabel && !started ? (
          <span
            className="video-overlapping-label"
            style={
              videoOverlappingLabel.coloredTextColor &&
              ({
                backgroundColor: `var(--${videoOverlappingLabel.coloredTextColor})`,
                color: `var(--${
                  videoOverlappingLabel.coloredTextColor === 'navy'
                    ? LABEL_LIGHT_FONT_TEXT
                    : LABEL_DARK_FONT_TEXT
                })`,
              } as React.CSSProperties)
            }
          >
            {videoOverlappingLabel.coloredText}
          </span>
        ) : null}

        <div className="embed-responsive embed-responsive-16by9">
          {previewImg?.featuredImage?.gatsbyImage?.childImageSharp ? (
            !started ? (
              <GatsbyImage
                fluid={previewImg.featuredImage.gatsbyImage.childImageSharp.fluid}
                alt={previewImg.imgAlt}
              />
            ) : null
          ) : null}

          {videoId.includes('http') ? (
            <video
              controls
              className={videoClasses('video-frame__player')}
              onPlay={toggleHTMLVideoPlay}
              onPause={toggleHTMLVideoPlay}
              ref={htmlVideo}
            >
              <source src={videoId} type="video/mp4" />
              <track default kind="captions" />
            </video>
          ) : (
            <YouTube
              className={videoClasses('video-player__youtube')}
              videoId={videoId}
              opts={YToptions}
              onReady={onYTVideoReady}
              onEnd={onYTVideoEnded}
              onStateChange={onYTPlayerStateChange}
            />
          )}

          {!started ? (
            <VideoButton
              btnPlayAriaLabel={btnPlayAriaLabel}
              btnPlay={btnPlay}
              onClick={toggleStart}
            />
          ) : null}
        </div>

        {videoBottomOverlappingTitle && !started ? (
          <p className="video-bottom-overlapping-title">{videoBottomOverlappingTitle}</p>
        ) : null}
      </div>
      {btn ? <NFButton {...btn} /> : null}
    </div>
  );
};

export default Video;
